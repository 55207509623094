import React from 'react';

import { conditionalClasses } from '@/utils';

export interface HeadlineProps extends React.HTMLProps<HTMLHeadingElement> {
  children: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  type?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'xxxs';
  className?: string;
}

const _NOT_PURGE_CLASSES = [
  'text-headline-xl',
  'leading-headline-xl',
  'tracking-headline-xl',
  'text-headline-l',
  'leading-headline-l',
  'tracking-headline-l',
  'text-headline-m',
  'leading-headline-m',
  'tracking-headline-m',
  'text-headline-s',
  'leading-headline-s',
  'tracking-headline-s',
  'text-headline-xs',
  'leading-headline-xs',
  'tracking-headline-xs',
  'text-headline-xxs',
  'leading-headline-xxs',
  'tracking-headline-xxs',
  'text-headline-xxxs',
  'leading-headline-xxxs',
  'tracking-headline-xxxs',
];

export const Headline: React.FC<HeadlineProps> = ({
  children,
  as: Component = 'h1',
  type = 'xl',
  className,
  ...rest
}) => (
  <Component
    {...rest}
    className={conditionalClasses(
      [
        `text-headline-${type} leading-headline-${type} tracking-headline-${type}`,
        'font-medium font-secondary',
      ],
      className,
    )}
  >
    {children}
  </Component>
);
